import React from 'react';
import Container from 'common/components/UI/Container';
import Image from 'common/components/Image';
import {Link} from "gatsby"

import {
  Section,
  ContentWrapper,
  Copyright,
  Nav,
} from './footer.style';

import logoDark from 'common/assets/image/saasMinimal2/logo-black.png';

const Footer = () => {
  const links = [
    {id: 'agb', url: '/agb', title: 'AGB'},
    {id: 'imprint', url: '/imprint', title: 'Impressum'},
    {id: 'contact', url: '/contact-us', title: 'Kontakt'},
  ]

  return (
    <Section>
      <Container>
        <ContentWrapper>
          <Copyright>
            <figure>
              <Image src={logoDark} alt="easyresto" style={{
                height: '64px',
              }} />
            </figure>
            <p>
              © {new Date().getFullYear()} <Link to="/">Easyresto</Link>
            </p>
          </Copyright>
          <Nav>
            {links.map((item) => (
              <li key={item.id}>
                <Link to={item.url}>{item.title}</Link>
              </li>
            ))}
          </Nav>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default Footer;
