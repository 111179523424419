import {FormControl} from "baseui/form-control";
import {Input} from "baseui/input";
import {Checkbox} from "baseui/checkbox";
import React from "react";
import {Button} from "baseui/button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import {Check, Delete} from 'baseui/icon';
import {RadioGroup, Radio, ALIGN} from "baseui/radio";
import {loadLangFromLocalStorage} from "../../../components/language-modal";
import getData from "../../../data/restaurant-webshop";

const getContactFormTranslations = () => {
  const lang = loadLangFromLocalStorage() || 'de';
  const result = getData(lang, 'contactForm');
  result['direction'] = getData(lang, 'direction');
  return result;
}


const encode = (data) => {
  return Object.keys(data)
    .map(
      (key) =>
        encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
    )
    .join("&");
}


export default ({
                  onSubmitSuccess = () => {
                  }
                }) => {
  const [resultData, setResultData] = React.useState({
    open: false,
    title: '',
    message: '',
  });
  const defaultForm = {
    name: {value: '', error: ''},
    restaurant: {value: '', error: ''},
    mobile: {value: '', error: ''},
    email: {value: '', error: ''},
    zipCode: {value: '', error: ''},
    agb: {value: false, error: ''},
    plan: {value: 'yearly', error: ''},
    fee: {value: 'one-time', error: ''},
  };
  const translations = getContactFormTranslations();

  const [form, setForm] = React.useState(defaultForm);

  const onFieldChange = (name, value) => {
    let error = '';
    if (name === 'plan' && value === 'monthly') {
      setForm({
        ...form,
        [name]: {value, error},
        fee: {value: 'one-time', error},
      });
    } else {
      setForm({
        ...form,
        [name]: {value, error},
      });
    }
  }

  const submitForm = async () => {
    let newForm = {};
    Object.keys(form).forEach((key) => {
      if ((key === 'agb' && form[key].value === false) || (key !== 'agb' && form[key].value === '')) {
        newForm[key] = {value: form[key].value, error: translations['required']};
      }
      let emailMatcher = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (key === 'email' && !(form[key].value).toLowerCase().match(emailMatcher)) {
        newForm[key] = {value: form[key].value, error: translations['emailRequired']};
      }
    });
    const isCorrect = Object.keys(newForm).length === 0;
    if (!isCorrect) {
      setForm({
        ...form,
        ...newForm,
      });
      throw Error('invalid inputs');
    } else {
      await sendForm();
    }
  }

  const resetForm = (e) => {
    setForm(defaultForm);
    e.target.reset();
  }

  const sendForm = () => {
    const fields = {};
    Object.keys(form).forEach((key) => {
      fields[key] = `${form[key].value}`
    });

    fetch("/restaurant-webshop", {
      method: "POST",
      headers: {"Content-Type": "application/x-www-form-urlencoded"},
      body: encode({
        "form-name": 'contact-form',
        ...fields,
      }),
    }).then(data => {
      if (data.status !== 200) {
        throw Error('failed!');
      }
      setForm(defaultForm);
      setResultData({
        open: true,
        title: <>
          <div style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '100%', height: '32px', width: '32px', background: 'green'
          }}>
            <Check size={24} color="#fff"/>
          </div>
          <span style={{paddingLeft: '16px'}}>{translations['thankYou']}</span></>,
        message: translations['thankYouMessage']
      });
      return true;
    }).catch(() => {
      setResultData({
        open: true,
        title: <>
          <div style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '100%', height: '32px', width: '32px', background: 'red'
          }}>
            <Delete size={24} color="#fff"/>
          </div>
          <span style={{paddingLeft: '16px'}}>{translations['error']}</span></>,
        message: translations['errorMessage']
      });
    });
    throw Error('failed!');
  }

  const onClose = () => {
    setResultData({...resultData, open: false});
    onSubmitSuccess();
  }

  const handleSubmit = async event => {
    event.preventDefault();
    submitForm().then(() => {
      resetForm(event);
    }).catch((e) => {
      console.log(e);
    });
  }
  return <form onSubmit={handleSubmit}
               method="POST"
               className=".form"
                style={{direction: translations.direction ? 'rtl' : 'ltr'}}
  >
    <FormControl
      label={() => translations['name']} error={form.name.error}>
      <Input size="compact" onChange={(e) => {
        onFieldChange('name', e.currentTarget.value);
      }} required/>
    </FormControl>
    <FormControl
      label={() => translations['restaurantName']} error={form.restaurant.error}>
      <Input size="compact" onChange={(e) => {
        onFieldChange('restaurant', e.currentTarget.value);
      }} required/>
    </FormControl>
    <FormControl
      label={() => translations['mobile']} error={form.mobile.error}>
      <Input size="compact" onChange={(e) => {
        onFieldChange('mobile', e.currentTarget.value);
      }} required/>
    </FormControl>
    <FormControl
      label={() => translations['email']} error={form.email.error}>
      <Input size="compact" onChange={(e) => {
        onFieldChange('email', e.currentTarget.value);
      }} required/>
    </FormControl>
    <FormControl
      label={() => translations['zipCode']} error={form.zipCode.error}>
      <Input size="compact" onChange={(e) => {
        onFieldChange('zipCode', e.currentTarget.value);
      }} required/>
    </FormControl>
    <FormControl label={() => translations['plan']} error={form.plan.error}>
      <RadioGroup
        value={form.plan.value}
        key={'plan'}
        onChange={e => {
          onFieldChange('plan', e.currentTarget.value);
        }}
        name="plan"
        align={ALIGN.vertical}>
        <Radio value="monthly" description={translations['monthlyDescription']}>{translations['monthly']}</Radio>
        <Radio
          value="yearly"
          description={translations['yearlyDescription']}>
          {translations['yearly']}
        </Radio>
      </RadioGroup>
    </FormControl>
    <FormControl label={() => translations['oneTimeFee']} error={form.fee.error}>
      <RadioGroup
        value={form.fee.value}
        key={'fee'}
        onChange={e => {
          onFieldChange('fee', e.currentTarget.value);
        }}
        name="fee"
        align={ALIGN.vertical}>
        <Radio value="one-time" description={translations['oneTimeFeeDescription']}>{translations['oneTimeFeeValue']}</Radio>
        <Radio
          value="installment"
          disabled={form.plan.value !== 'yearly'}
          overrides={{
            Label: {
              style: {textDecoration: (form.plan.value !== 'yearly' ? 'line-through' : 'inherit')}
            }
          }}
          description={translations['oneTimeFeeInInstallmentDescription']}>
          {translations['oneTimeFeeInInstallment']}
        </Radio>
      </RadioGroup>
    </FormControl>
    <FormControl error={form.agb.error}>
      <Checkbox
        checked={form.agb.value}
        onChange={() => {
          onFieldChange('agb', !form.agb.value);
        }} overrides={{
        Label: {
          style: {fontSize: '14px'}
        }
      }}>
        {translations['agbConfirm']}
      </Checkbox>
    </FormControl>
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <Button type="submit" overrides={{
        Root: {
          style: {margin: '8px 0 32px'}
        }
      }}>{translations['submit']}</Button>
    </div>

    <Modal onClose={onClose} isOpen={resultData.open} overrides={{
      Root: {
        style: {zIndex: 9999}
      }
    }}>
      <ModalHeader>{resultData.title}</ModalHeader>
      <ModalBody>
        {resultData.message}
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={onClose}>{translations['ok']}</ModalButton>
      </ModalFooter>
    </Modal>
  </form>;
}