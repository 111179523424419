import * as React from "react";
import {
  AppNavBar,
  setItemActive
} from "baseui/app-nav-bar";
import Logo from 'common/components/UIElements/Logo';
import {Link} from "gatsby"

import logoDark from 'common/assets/image/saasMinimal2/logo-black.png';
import {Button} from "baseui/button";
import {Navbar} from './navbar.style';
import LanguageModal from "../../../components/language-modal";
import ContactModal from "../../../components/contact-modal";

export default ({activeItemId, overrideMenuItems, hideMobileMenu}) => {
  const [mainItems, setMainItems] = React.useState([
    {label: "Startseite", active: (activeItemId === 'home'), info: {link: '/'}},
    {label: "Preise", active: (activeItemId === 'price'), info: {link: '/price'}},
    {label: "Kontakt", active: (activeItemId === 'contact'), info: {link: '/contact-us'}},
    {label: 'Anmeldung', info: {type: 'button', kind: 'minimal', link: 'https://my.easyresto.de'}},
    {label: 'KOSTENFREIE DEMO', info: {type: 'button', kind: 'primary', link: '/contact-us'}},
  ]);
  if (overrideMenuItems && hideMobileMenu) {
    return <Navbar><Link
      to="/"><Logo
      logoSrc={logoDark}
      title="Easyresto"
      logoStyle={{
        height: '52px',
      }}
    /></Link>
      {
        overrideMenuItems && <div style={{display: 'flex', gap: '0 8px'}}>
          <LanguageModal label={overrideMenuItems[1].label}/>
          <ContactModal label={overrideMenuItems[0].label} size="compact"/>
        </div>
      }
    </Navbar>
  }
  return (
    <AppNavBar
      title={<Link
        to="/"><Logo
        logoSrc={logoDark}
        title="Easyresto"
        logoStyle={{
          height: '52px',
        }}
      /></Link>}
      mainItems={(overrideMenuItems) ? (hideMobileMenu ? null : overrideMenuItems) : mainItems}
      mapItemToNode={(item) => <Link style={{
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        padding: '20px 0',
      }} to={`${item.info ? item.info.link : '#'}`}>
        {(item.info && item.info.type === 'button') ?
          <Button size="compact" kind={item.info.kind}>{item.label}</Button> : item.label}
      </Link>}
      onMainItemSelect={item => {
        setMainItems(prev => setItemActive(prev, item));
      }}
      overrides={{
        Root: {
          style: {
            borderBottom: '1px solid #eee',
          }
        },
        AppName: {
          style: {
            width: '100%'
          }
        },
        MainMenuItem: {
          style: {
            fontWeight: 'bold',
            display: 'flex',
            padding: '0 !important',
          }
        },
        MobileDrawer: {
          props: {
            overrides: {
              Root: {
                style: {
                  zIndex: 11,
                }
              }
            }
          },
        },
      }}
    />
  );
}