import React from 'react';
export default (images, pageAction) => ({
  title: 'Restoran mağazası',
  navbarMenuItems: [
    {label: 'Şimdi başla'},
    {label: 'Dilinizi seçin:'}
  ],
  banner: {
    subTitle: 'Basit, bireysel ve komisyonsuz',
    title: 'Kendi çevrimiçi restoran mağazanız',
    description: 'Sezgisel bir çevrimiçi sipariş deneyimi sunun. Daha fazla müşteriye ulaşın ve daha fazla satış yapın.',
    actionLabel: 'Şimdi başla',
    actionLink: pageAction.link,
  },
  reviews: {
    title: 'müşterilerimiz memnun',
    data: [
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/111-logo.png?v=1606747893.217734',
        review: "Easyresto işimi temelden değiştirdi. Sistemin kullanımı son derece kolay ve müşterilerin sadece" +
          " birkaç tıklama ile doğrudan web sitemden sipariş vermelerine olanak tanıyor. Süreç son derece sorunsuz ve hızlı ve satışlarımı önemli ölçüde artırdı. Easyresto'yu çevrimiçi sipariş almanın kolay ve verimli bir yolunu arayan tüm restoran sahiplerine tavsiye edebilirim.",
        name: 'Raphael Jonas Holczer',
        restaurant: 'Lausbub',
        designation: 'Siteyi ziyaret et',
        restaurantUrl: 'https://order.lausbub-pizza.de'
      },
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/142-logo.png?v=1632748765.548651?h=80',
        review: "\"Easyresto'yu birkaç aydır kullanıyoruz ve sonuçlardan kesinlikle çok memnunuz! Kullanımı çok kolay ve müşterilerimiz bu rahatlığa bayılıyor. Satışlarımızın artmasına yardımcı oldu ve müşterilerimiz sipariş sürecinden çok memnun. easyresto'yu müşterilerine en iyi çevrimiçi sipariş sistemini sunmak isteyen tüm restoranlara tavsiye edebilirim!",
        name: 'Naschuan Hassan',
        restaurant: 'Burger n\' Friends',
        designation: 'Siteyi ziyaret et',
        restaurantUrl: 'https://burgernfriends.easyresto.de'
      },
      {
        image: 'https://storage.googleapis.com/static.easypickup.de/images/118-logo.png?v=1611679035.095402',
        review: "Pizz & Via olarak easyresto ile daha mutlu olamazdık. Kurulumu hızlı ve kolaydı ve müşterilerimize birinci sınıf bir çevrimiçi sipariş deneyimi sunuyor. Müşteri hizmetleri ekibi her zaman yardımcı oluyor ve tavsiyeleri birinci sınıf. easyresto'yu verimli ve güvenilir bir çevrimiçi sipariş sistemi arayan tüm restoranlara şiddetle tavsiye ediyoruz!",
        name: 'Tullio Marotta',
        restaurant: 'Pizz&Via',
        designation: 'Siteyi ziyaret et',
        restaurantUrl: 'https://delivery.pizzevia.de'
      },
      {
        image: 'https://easyresto.imgix.net/images/f783b3b2-f92b-47d4-b39e-d1daf29aafa5.png?h=180',
        review: "Easyresto, Afrika restoranımız Blue Zebra için harika bir çevrimiçi sipariş sistemidir. Birkaç aydır kullanıyoruz ve kolaylaştırılmış süreç, müşterilerimizin lezzetli yemeklerimizi sipariş etmesini çok kolaylaştırıyor. Arayüz çok sezgiseldir ve müşterilerin aradıklarını bulmalarını ve hızlı bir şekilde sipariş vermelerini kolaylaştırır. Bir sorumuz olduğunda müşteri hizmetleri ekibine her zaman ulaşabiliyoruz. Bu çevrimiçi sipariş sistemini şiddetle tavsiye ediyoruz!",
        name: 'Taba Keutcha',
        restaurant: 'Blaues Zebra',
        designation: 'Siteyi ziyaret et',
        restaurantUrl: 'https://blaueszebra.de'
      },
    ]
  },
  calculator: {
    title: 'Easyresto ile ne kadar tasarruf ediyorsunuz?',
    provision: 'portal komisyonu',
    ordersPerMonth: 'aylık siparişler',
    orderValue: 'sipariş değeri',
    result: 'Sonuç:',
    perMonth: 'Her ay'
  },
  features: [
    {
      slogan: '%100 daha fazla görünürlük',
      title: 'Google\'da diğer sağlayıcılara göre daha üst sıralarda yer alır',
      description: 'Diğer sağlayıcılara kıyasla google\'da daha üst sıralarda yer alır\n' +
        'Sistemimiz, günümüzün arama motoru optimizasyonu standartlarının en iyi uygulamalarını karşılayacak şekilde tasarlanmıştır. Web sitenizin her zaman en güncel SEO stratejileri ile güncel olduğundan emin olmak için en son teknolojiyi kullanıyoruz. Bu, web sitenizin her zaman diğer sağlayıcılardan daha üst sıralarda yer almasını sağlamaya yardımcı olur. Ayrıca arama motorlarının sürekli değişen algoritmalarına ayak uydurmaya özen gösteriyoruz.\n',
      image: {
        src: images.googlerank.childImageSharp.gatsbyImageData,
        alt: '',
      }
    },
    {
      slogan: 'Bir saniyelik gecikme nedeniyle müşteri memnuniyeti %16 oranında düşüyor.',
      title: 'Mobil cihazlarda yıldırım hızıyla yüklenir',
      description: 'Sistemimiz, diğer sağlayıcılara kıyasla ortalama %30\'a kadar daha hızlı yükleme süreleri sağlamak üzere tasarlanmıştır. Daha hızlı yüklenen web siteleri, gelişmiş müşteri memnuniyeti, artan dönüşümler ve daha iyi arama motoru sıralamaları gibi birçok fayda sağlar.',
      image: {
        src: images.pagespeed.childImageSharp.gatsbyImageData,
        alt: '',
      }
    },
    {
      slogan: 'Tüketicilerin %88\'inin kullanıcı deneyimi kötü olan bir siteye geri dönme olasılığı daha düşüktür.',
      title: 'Sezgisel kullanıcı deneyimi',
      description: 'easyresto sadece diğer webshop sistemlerinden daha hızlı olmakla kalmaz, aynı zamanda sipariş vermeyi kolay ve verimli hale getiren sezgisel ve kullanıcı dostu bir arayüz sağlar. Sadece birkaç tıklama ile müşteriler ihtiyaç duydukları ürünleri hızlı ve kolay bir şekilde sipariş edebilirler. Diğer bazı sağlayıcıların aksine, karmaşık menülerden ve sipariş süreçlerinden geçmek zorunda kalmazlar.',
      image: {
        src: images.ux.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
  ],
  contact: {
    callToAction: '',
    title: 'Kolaylıkla Sipariş Verin - Easyresto!',
    description: 'Restoranınızı bir üst seviyeye taşıma fırsatını kaçırmayın - Easyresto\'ya şimdi kaydolun ve kârınızı artırmaya başlayın!',
    action: {
      label: 'Şimdi Başlayın',
    },
    features: [
      'Whatsapp ve E-posta Desteği',
      'Ücretsiz Onboarding',
      '48 saatten kısa sürede başlayın',
    ],
    image: {
      src: images.hero.childImageSharp.gatsbyImageData,
      alt: '',
    }
  },
  nextFeatures: [
    {
      slogan: 'Her yerden, her zaman erişim',
      title: 'Web mağazanızı telefonunuzdan yönetin. Arka uç duyarlı',
      description: 'Eğer bir restoran sahibiyseniz, en son teknolojiyi takip etmenin ne kadar önemli olduğunu bilirsiniz. EasyResto ile web mağazanızı telefonunuzdan, istediğiniz zaman, istediğiniz yerden yönetebilirsiniz. Duyarlı arka ucumuz, web mağazanızı birkaç basit dokunuşla kolayca yönetmenizi sağlar.',
      image: {
        src: images.responsive.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
    {
      slogan: 'Kredi/Banka Kartları, Klarna, Sofort, Paypal, Giropay ve çok daha fazlası',
      title: 'Çoklu Ödeme Yöntemleri',
      description: 'Easyresto webshop sistemi, kullanıcılara alışveriş yaparken tercih ettikleri ödeme yöntemini seçme kolaylığı sunar. Kullanıcılara kredi kartı, PayPal, Google Pay, Apple Pay ve diğer ödeme yöntemleri seçeneklerini sunuyoruz. Tüm ödeme yöntemleri güvenli ve emniyetlidir ve tüm işlemler şifrelenir.',
      image: {
        src: images.payments.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
    {
      slogan: 'Siparişleriniz için tek tıkla dışa aktarma',
      title: 'Vergi Danışmanları için Raporlar',
      description: 'Vergi danışmanları için güvenilir ve doğru fatura muhasebesi bilgilerine sahip olmanın ne kadar önemli olduğunu biliyoruz, bu nedenle kapsamlı ve kullanıcı dostu web tabanlı sistemimizle fatura kayıtlarını takip etmenin zorluğunu ortadan kaldırıyoruz. Sistemimiz, süreçleri kolaylaştırmak ve vergi danışmanlarının müşterilerinin mali durumlarını yönetmelerini kolaylaştırmak için tasarlanmıştır.',
      image: {
        src: images.tax.childImageSharp.gatsbyImageData,
        alt: '',
      },
    },
  ],
  contactForm: {
    'required': '* Gerekli',
    'emailRequired': '* Lütfen doğru bir e-posta adresi verin.',
    'thankYou': 'TEŞEKKÜRLER',
    'thankYouMessage': 'Talebinizi aldık ve mümkün olan en kısa sürede sizinle iletişime geçeceğiz.',
    'error': 'Hata',
    'errorMessage': 'Lütfen bir kez daha deneyin.',
    'name': 'İsim*',
    'restaurantName': 'Restoran adı*',
    'mobile': 'Mobile*',
    'email': 'Email*',
    'zipCode': 'Posta Kodu*',
    'plan': 'Sizin Planınız*',
    'monthly': 'Aylık - 69,00 €',
    'monthlyDescription': '* istediğiniz zaman iptal edin',
    'yearly': 'Yıllık - 59,00 €',
    'yearlyDescription': '* 12 aylık sözleşme',
    'oneTimeFee': 'Tek seferlik ücret [sipariş cihazı + pazarlama materyalleri]',
    'oneTimeFeeValue': '300,00 €',
    'oneTimeFeeDescription': '* sadece bir kez ödendi',
    'oneTimeFeeInInstallment': '25,00 € / ay',
    'oneTimeFeeInInstallmentDescription': '* ilk 12 ay için taksit',
    'agbConfirm': <>Kabul ediyorum <a href="/agb" target="_blank">Şartlar ve Koşullar ve Gizlilik Politikası</a>.</>,
    'submit': 'Bize ulaşın',
    'ok': 'Tamam',
  },
});