import {createTheme} from 'baseui';

const primitives = {
  primary: '#6600f1',
  primary50: '#EDE0FD',
  primary100: '#D1B3FB',
  primary200: '#B380F8',
  primary300: '#944DF5',
  primary400: '#7D26F3',
  primary500: '#6600F1',
  primary600: '#5E00EF',
  primary700: '#5300ED',
  accent: '#FF0072', // hot pink
  accent50: '#FFE0EE',
  accent100: '#FFB3D5',
  accent200: '#FF80B9',
  accent300: '#FF4D9C',
  accent400: '#FF2687',
  accent500: '#FF0072',
  accent600: '#FF006A',
  accent700: '#FF005F',
};

const overrides = {
  colors: {
    buttonPrimaryText: '#fff',
    buttonSecondaryFill: '#36cc52',
    buttonSecondaryText: '#fff',
    buttonSecondaryHover: '#36cc52c4',
    buttonSecondaryActive: '#25b53e',
    buttonTertiaryFill: '#EEEEEE',
    buttonTertiaryText: '#000000',
    buttonTertiaryHover: '#E2E2E2',
    buttonTertiaryActive: '#CBCBCB',
    buttonMinimalFill: 'transparent',
    buttonMinimalText: primitives.primary,
    buttonMinimalHover: primitives.primary300,
    buttonMinimalHoverText: '#fff',
    buttonMinimalActive: primitives.primary700,
  }
}


export const theme = createTheme(primitives, overrides);